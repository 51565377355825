<template>
  <b-card
    class="purchaseinboundorderitem-edit-wrapper"
  >
    <!-- form -->
    <b-form id="purchaseinboundorderitemForm" class="edit-form mt-2">
      <b-row>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label=""
            label-for="in_order_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="in_order_id"
              size="sm"
              v-model="purchaseinboundorderitem.in_order_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品ID"
            label-for="product_id"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_id"
              size="sm"
              v-model="purchaseinboundorderitem.product_id"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="名称"
            label-for="name"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="name"
              size="sm"
              v-model="purchaseinboundorderitem.name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品编码(69码)"
            label-for="code"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="code"
              size="sm"
              v-model="purchaseinboundorderitem.code"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="商品规格"
            label-for="specification"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="specification"
              size="sm"
              v-model="purchaseinboundorderitem.specification"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="单箱标准数量"
            label-for="box_quantity"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="box_quantity"
              size="sm"
              v-model="purchaseinboundorderitem.box_quantity"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="实际入库数量"
            label-for="trueqty"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="trueqty"
              size="sm"
              v-model="purchaseinboundorderitem.trueqty"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="采购单价 purchase_order_item.product_cost_tax"
            label-for="product_cost_tax"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="product_cost_tax"
              size="sm"
              v-model="purchaseinboundorderitem.product_cost_tax"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="发货金额 product_cost_tax * trueqty"
            label-for="subtotal"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="subtotal"
              size="sm"
              v-model="purchaseinboundorderitem.subtotal"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="净含量(ml)"
            label-for="netweight"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="netweight"
              size="sm"
              v-model="purchaseinboundorderitem.netweight"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="启用"
            label-for="state"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="state"
              size="sm"
              v-model="purchaseinboundorderitem.state"
            />
          </b-form-group>
        </b-col>
                <b-col
          cols="12"
          class="mt-50"
        >
          <b-button
            variant="primary"
            class="mr-1"
            @click="save"
          >
            保存
          </b-button>
          <b-button
            variant="outline-secondary"
            @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import purchaseinboundorderitemStore from './purchaseinboundorderitemStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      purchaseinboundorderitem: ref({}),
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('purchaseinboundorderitem')) store.registerModule('purchaseinboundorderitem', purchaseinboundorderitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('purchaseinboundorderitem')) store.unregisterModule('purchaseinboundorderitem')
    })

    const edit = function() {
      store.dispatch('purchaseinboundorderitem/edit', {id: this.id}).then(res => {
        this.purchaseinboundorderitem = res.data.data
      })
    }

    const view = function() {
      store.dispatch('purchaseinboundorderitem/view', {id: this.id}).then(res => {
        this.purchaseinboundorderitem = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      store.dispatch('purchaseinboundorderitem/save', this.purchaseinboundorderitem).then(res => {
        toast.success('数据已保存!')
        this.$router.push({ name: 'apps-${classVar}-list'});
      })
    }

    return {
      edit,
      view,
      cancel,
      save,
      
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>